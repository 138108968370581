<template>
  <div class="AppBar mx-auto overflow-hidden">
    <v-system-bar color="deep-purple darken-3" />

    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      color="primary"
      temporary
    >
      <v-app-bar
        color="primary darken-2"
        flat
        dark
        dense
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <v-toolbar-title>Pepsico Fuel</v-toolbar-title>
      </v-app-bar>
      <v-sheet
        color="transparent"
        class="pa-4"
        dark
      >
        <v-row no-gutters>
          <v-col
            cols="4"
            md="4"
          >
            <v-avatar
              class="mb-4"
              size="64"
            >
              <v-icon x-large>
                fas fa-user-circle
              </v-icon>
            </v-avatar>
          </v-col>
          <v-col>
            <div style="margin-top: 20px;">
              {{ userName }}
            </div>
          </v-col>
        </v-row>
      </v-sheet>
      <v-list
        nav
        dense
        dark
      >
        <v-list-item-group>
          <v-list-item
            v-for="(item, i) in itemsFilter"
            :key="i"
            :to="{ name: item.route }"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      color="primary"
      dark
      flat
      dense
    >
      <v-app-bar-nav-icon @click="drawer = !drawer" />
      <v-toolbar-title>Pepsico Fuel</v-toolbar-title>
      <v-spacer />
      <v-menu
        left
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-avatar
              size="36"
            >
              <v-icon> fas fa-user-circle </v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-card
          style="border-radius: 0px;"
        >
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  x-large
                  color="primary"
                >
                  fas fa-user-circle
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ userName }}</v-list-item-title>
                <v-list-item-subtitle>{{ memberSince }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ userRole }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="logout()"
            >
              {{ $t('appBar.logout') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>
  </div>
</template>
<script>
import dateFormatMixin from '@/mixins/dateFormat';

export default {
  name: 'AppBar',
  components: {},
  mixins: [dateFormatMixin],
  data() {
    return {
      drawer: null,
      drawerItems: [
        {
          text: this.$t('appBar.search'), icon: 'fas fa-search', route: 'search', authorize: ['superadmin', 'facilitador', 'inase'],
        },
        {
          text: this.$t('appBar.import'), icon: 'fas fa-project-diagram', route: 'importxlsx', authorize: ['superadmin'],
        },
        {
          text: this.$t('appBar.reports'), icon: 'fas fa-file', route: 'reports', authorize: ['superadmin', 'facilitador'],
        },
        {
          text: this.$t('appBar.employees'), icon: 'fas fa-users', route: 'employees', authorize: ['superadmin', 'facilitador', 'inase'],
        },
        {
          text: this.$t('appBar.settings'), icon: 'fas fa-cog', route: 'settings', authorize: ['superadmin', 'facilitador'],
        },
      ],
    };
  },
  computed: {
    userName() {
      return JSON.parse(localStorage.getItem('user')).name;
    },
    itemsFilter() {
      return this.drawerItems.filter( (item) => item.authorize.includes(this.userRole ));
    },
    userRole() {
      return JSON.parse(localStorage.getItem('user')).role;
    },
    memberSince() {
      const date = this.formatShowDate(JSON.parse(localStorage.getItem('user')).created).split(' ') [0];
      return (this.$t('session.memberSince') + ' ' + date);
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('session/logout');
    },
  },
};
</script>
