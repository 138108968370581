<template>
  <div class="Dashboard">
    <AppBar />
    <v-main class="pt-2 pt-md-0">
      <router-view />
      <v-snackbar
        v-model="expired"
        :timeout="-1"
        absolute
        right
        text
        top
        color="error"
      >
        <strong> {{ $t('session.expired') }} </strong>
        <br />
        {{ $t('session.redirect') }}
        <v-progress-linear
          indeterminate
          color="error"
          absolute
          bottom
        />
      </v-snackbar>
    </v-main>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import AppBar from '@/components/AppBar.vue';
export default {
  name: 'Dashboard',
  components: { AppBar },
  computed: { ...mapState('session', ['expired']) },
  mounted() {
    this.$store
        .dispatch('ui/dashboardBackground');
  },
};

</script>
<style>
div.v-menu__content.theme--light.menuable__content__active {
  box-shadow: none;
  border-radius: 0;
  border: thin solid rgba(0, 0, 0, 0.12);
}
.v-card__text, .v-card__title {
  word-break: normal;
}
</style>
